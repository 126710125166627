import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => {
  if (typeof window !== "undefined") {
    let pathParts = window.location.pathname.split("/")
    pathParts = pathParts.slice(0, pathParts.length - 2)
    window.location.pathname = pathParts.join("/")
  }

  return null
}

export default NotFoundPage
